import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, AutocompleteInput, required } from 'react-admin';
import { useHistory } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { GoogleAutocomplete } from 'components/molecules';

import itProvinces from 'helpers/data/it-provinces.json';
import brStates from 'helpers/data/br-states.json';
import countries from 'helpers/data/countries.json';

import { locationExists } from 'providers/Locations/index';

const AddressInputs = ({
  currentAddress,
  currentName,
  currentCountry,
  componentsAfter,
}) => {
  const history = useHistory();

  const nameInputRef = useRef();
  const addressInputRef = useRef();

  const name = useRef(currentName).current;
  const address = useRef(currentAddress).current;

  const [openModal, setOpenModal] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (clear) history.go(0);
  }, [clear, history]);

  const handleBlur = async () => {
    if (!currentAddress || !currentName) return;

    if (currentName === name && currentAddress === address) return;

    const exists = await locationExists({
      name: currentName,
      address: currentAddress,
    });

    if (exists) {
      setOpenModal(true);
    }
  };

  const handleRegisterAnother = () => setClear(true);

  const handleNameSuggestionSelect = () => {
    nameInputRef.current.focus();
  };

  return (
    <>
      <Dialog fullWidth open={openModal}>
        <DialogTitle id="max-width-dialog-title">
          This location has already been registered
        </DialogTitle>
        <DialogActions>
          <Button onClick={history.goBack}>Cancel</Button>
          <Button onClick={handleRegisterAnother} color="primary">
            Register another one
          </Button>
        </DialogActions>
      </Dialog>
      <GoogleAutocomplete
        inputRef={nameInputRef}
        onBlur={handleBlur}
        initialValue={currentName}
        onSuggestionSelect={handleNameSuggestionSelect}
        type="name"
      />
      {componentsAfter.get('name')}
      <GoogleAutocomplete
        inputRef={addressInputRef}
        onBlur={handleBlur}
        initialValue={currentAddress}
        type="address"
      />
      {componentsAfter.get('address')}
      <TextInput
        source="city"
        label="City"
        validate={required('Location city is required')}
      />
      {componentsAfter.get('city')}
      <AutocompleteInput
        key="code"
        label="Province"
        source="province"
        choices={currentCountry === 'IT' ? itProvinces : brStates}
        optionText="name"
        optionValue="code"
        shouldRenderSuggestions={val => {
          return val && val.trim().length > 1;
        }}
        validate={required('Location province is required')}
      />
      {componentsAfter.get('province')}
      <AutocompleteInput
        key="shortName"
        label="Country"
        source="country"
        choices={countries}
        optionText="name"
        optionValue="shortName"
        shouldRenderSuggestions={val => {
          return val && val.trim().length > 1;
        }}
        validate={required('Country is required')}
      />
      {componentsAfter.get('country')}
      <div>
        <TextInput
          source="latitude"
          label="Latitude"
          validate={required('Latitude is required')}
          style={{ marginRight: '20px' }}
        />
        <TextInput
          source="longitude"
          label="Longitude"
          validate={required('Longitude is required')}
        />
      </div>
      {componentsAfter.get('longitude')}
    </>
  );
};

AddressInputs.propTypes = {
  currentAddress: PropTypes.string,
  currentName: PropTypes.string,
  componentsAfter: PropTypes.instanceOf(Map),
};

AddressInputs.defaultProps = {
  currentAddress: '',
  currentName: '',
  componentsAfter: new Map(),
};

export default AddressInputs;
