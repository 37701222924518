import gql from 'graphql-tag';

export const SAVE_VACCINE = gql`
  mutation saveVaccine(
    $id: ID
    $name: String!
    $language: String!
    $typeId: ID!
  ) {
    saveVaccine(
      id: $id
      vaccineInput: { name: $name, language: $language, typeId: $typeId }
    ) {
      id
    }
  }
`;

export const DELETE_VACCINE = gql`
  mutation deleteVaccine($id: ID!) {
    deleteVaccine(id: $id)
  }
`;
