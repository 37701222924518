import client from 'services/graphql/client';
import { LOG_IN } from './mutations';

const userLogIn = (email, password) =>
  client.mutate({
    mutation: LOG_IN,
    variables: {
      email,
      password,
    },
  });

export { userLogIn };
