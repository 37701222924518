import * as Categories from './Categories';
import * as Subcategories from './Subcategories';
import * as Locations from './Locations';
import * as PetTypes from './PetTypes';
import * as PetBreeds from './PetBreeds';
import * as PetFurs from './PetFurs';
import * as AdminUsers from './AdminUsers';
import * as AppUsers from './AppUsers';
import * as Vaccines from './Vaccines';

export default {
  getList: (resource, params) => {
    const resources = {
      categories: () => Categories.getList(params),
      subcategories: () => Subcategories.getList(params),
      locations: () => Locations.getList(params),
      petTypes: () => PetTypes.getList(params),
      petBreeds: () => PetBreeds.getList(params),
      petFurs: () => PetFurs.getList(params),
      vaccines: () => Vaccines.getList(params),
      adminUsers: () => AdminUsers.getList(params),
      appUsers: () => AppUsers.getList(params),
    };

    return resources[resource]();
  },
  getOne: (resource, params) => {
    const resources = {
      categories: () => Categories.getOne(params),
      subcategories: () => Subcategories.getOne(params),
      locations: () => Locations.getOne(params),
      petTypes: () => PetTypes.getOne(params),
      petBreeds: () => PetBreeds.getOne(params),
      petFurs: () => PetFurs.getOne(params),
      vaccines: () => Vaccines.getOne(params),
      adminUsers: () => AdminUsers.getOne(params),
      appUsers: () => AppUsers.getOne(params),
    };

    return resources[resource]();
  },
  getMany: (resource, params) => {
    const resources = {
      categories: () => Categories.getMany(params),
      subcategories: () => Subcategories.getMany(params),
      petTypes: () => PetTypes.getMany(params),
      petBreeds: () => PetBreeds.getMany(params),
      petFurs: () => PetFurs.getMany(params),
      vaccines: () => Vaccines.getMany(params),
    };

    return resources[resource]();
  },
  create: (resource, params) => {
    const resources = {
      categories: () => Categories.create(params),
      subcategories: () => Subcategories.create(params),
      locations: () => Locations.create(params),
      petTypes: () => PetTypes.create(params),
      petBreeds: () => PetBreeds.create(params),
      petFurs: () => PetFurs.create(params),
      vaccines: () => Vaccines.create(params),
      adminUsers: () => AdminUsers.create(params),
      appUsers: () => AppUsers.create(params),
    };

    return resources[resource]();
  },
  update: (resource, params) => {
    const resources = {
      categories: () => Categories.update(params),
      subcategories: () => Subcategories.update(params),
      locations: () => Locations.update(params),
      petTypes: () => PetTypes.update(params),
      petBreeds: () => PetBreeds.update(params),
      petFurs: () => PetFurs.update(params),
      vaccines: () => Vaccines.update(params),
      adminUsers: () => AdminUsers.update(params),
      appUsers: () => AppUsers.update(params),
    };

    return resources[resource]();
  },
  delete: (resource, params) => {
    const resources = {
      categories: () => Categories.deleteOne(params),
      subcategories: () => Subcategories.deleteOne(params),
      locations: () => Locations.deleteOne(params),
      petTypes: () => PetTypes.deleteOne(params),
      petBreeds: () => PetBreeds.deleteOne(params),
      petFurs: () => PetFurs.deleteOne(params),
      vaccines: () => Vaccines.deleteOne(params),
      adminUsers: () => AdminUsers.deleteOne(params),
      appUsers: () => AppUsers.deleteOne(params),
    };

    return resources[resource]();
  },
  getAll: (resource, params) => {
    const resources = {
      categories: () => Categories.getAll(),
      subcategories: () => Subcategories.getAll(params || {}),
      adminUsers: () => AdminUsers.getAll(params),
      appUsers: () => AppUsers.getAll(params),
    };

    return resources[resource]();
  },
  getAllCategories: resource => {
    if (resource === 'subcategories') return Subcategories.getAllCategories();
  },
};
