import gql from 'graphql-tag';

export const SAVE_CATEGORY = gql`
  mutation saveLocationCategory($id: ID, $name: String!, $language: String!) {
    saveLocationCategory(
      categoryInput: { name: $name, language: $language }
      id: $id
    ) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteLocationCategory($id: ID!) {
    deleteLocationCategory(id: $id)
  }
`;
