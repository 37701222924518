import React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { LocaleSwitcher } from '../../../components/atoms';

const useStyles = makeStyles({
  bar: {
    backgroundColor: '#9c2e85',
  },
});

const MyUserMenu = props => (
  <>
    <LocaleSwitcher />
    <UserMenu {...props} />
  </>
);

const CustomAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar className={classes.bar} {...props} userMenu={<MyUserMenu />} />
  );
};

export default CustomAppBar;
