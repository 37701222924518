import React from 'react';
import { Screen } from 'components/templates';
import { PetFurList, PetFurForm } from 'components/organisms';

export const List = props => (
  <Screen header="Pet furs">
    <PetFurList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Pet fur">
    <PetFurForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Pet fur">
    <PetFurForm action="Create" {...props} />
  </Screen>
);
