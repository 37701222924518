// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_address-wrapper__3v_TH {\n  position: relative; }\n  .styles_address-wrapper__3v_TH label {\n    text-transform: capitalize; }\n    .styles_address-wrapper__3v_TH label + div {\n      height: 48px; }\n  .styles_address-wrapper__3v_TH .styles_autocomplete__3Rc7T {\n    margin-bottom: 30px; }\n    .styles_address-wrapper__3v_TH .styles_autocomplete__3Rc7T input {\n      position: relative;\n      z-index: 10; }\n  .styles_address-wrapper__3v_TH .styles_input__2piPT {\n    left: 0;\n    margin-top: 0;\n    position: absolute;\n    top: 0; }\n    .styles_address-wrapper__3v_TH .styles_input__2piPT label {\n      color: transparent; }\n    .styles_address-wrapper__3v_TH .styles_input__2piPT input {\n      background-color: transparent;\n      color: transparent; }\n\n.styles_suggestions__BHmmH {\n  background: white;\n  border: 1px solid #ccc;\n  border-radius: 0 0 5px 5px;\n  cursor: pointer;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  top: 48px;\n  width: 100%;\n  z-index: 100; }\n  .styles_suggestions__BHmmH li {\n    border-bottom: 1px solid #ccc;\n    padding: 8px 12px; }\n    .styles_suggestions__BHmmH li:last-child {\n      border: none; }\n    .styles_suggestions__BHmmH li:hover {\n      background-color: #eee; }\n", ""]);
// Exports
exports.locals = {
	"address-wrapper": "styles_address-wrapper__3v_TH",
	"autocomplete": "styles_autocomplete__3Rc7T",
	"input": "styles_input__2piPT",
	"suggestions": "styles_suggestions__BHmmH"
};
module.exports = exports;
