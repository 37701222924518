import React, { createContext, useState } from 'react';

export const CountryContext = createContext();

export function CountryContextProvider(props) {
  const [countryData, setCountryData] = useState(() => {
    const data = localStorage.getItem('@helpetAdmin:countryData');
    if (data) {
      return JSON.parse(data);
    } else {
      localStorage.setItem(
        '@helpetAdmin:countryData',
        JSON.stringify({
          country: 'italy',
          language: 'it',
          shortName: 'IT',
        }),
      );
    }
    return {
      country: 'italy',
      language: 'it',
      shortName: 'IT',
    };
  });

  return (
    <CountryContext.Provider value={{ countryData, setCountryData }}>
      {props.children}
    </CountryContext.Provider>
  );
}
