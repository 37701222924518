import gql from 'graphql-tag';

export const LIST_CATEGORIES = gql`
  query locationCategories(
    $ids: [ID!]
    $name: String
    $take: Int
    $skip: Int
    $fieldSort: String
    $order: String
    $language: String
  ) {
    locationCategories(
      ids: $ids
      name: $name
      take: $take
      skip: $skip
      fieldSort: $fieldSort
      order: $order
      language: $language
    ) {
      items {
        id
        name
        language
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const GET_CATEGORY = gql`
  query locationCategory($id: ID!) {
    locationCategory(id: $id) {
      id
      name
      language
    }
  }
`;
