import decodeJwt from 'jwt-decode';
import { userLogIn } from './actions';

export default {
  login: async ({ username, password }) => {
    try {
      const {
        data: {
          login: { accessToken },
        },
      } = await userLogIn(username, password);
      const decodedToken = decodeJwt(accessToken);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('permissions', decodedToken.roles);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return Promise.resolve();
    } catch (error) {
      if (error.message.includes('error'))
        return Promise.reject(
          'The email or password entered is wrong, please try again.',
        );

      return Promise.reject(error);
    }
  },
  logout: async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('accessToken');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const roles = localStorage.getItem('permissions');
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};
