import gql from 'graphql-tag';

export const SAVE_SUBCATEGORY = gql`
  mutation saveLocationSubcategory(
    $id: ID
    $name: String!
    $language: String!
    $categoryId: ID!
  ) {
    saveLocationSubcategory(
      id: $id
      subcategoryInput: {
        categoryId: $categoryId
        name: $name
        language: $language
      }
    ) {
      id
      name
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation deleteLocationSubcategory($id: ID!) {
    deleteLocationSubcategory(id: $id)
  }
`;
