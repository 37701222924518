import client from 'services/graphql/client';
import { LIST_CATEGORIES, GET_CATEGORY } from './queries';
import { SAVE_CATEGORY, DELETE_CATEGORY } from './mutations';

const listCategories = (take = 10, skip = 0, field, order, q, language) => {
  const variables = {
    take,
    skip,
    fieldSort: field,
    order,
    language,
  };

  if (q) variables.name = q;

  return client.query({
    query: LIST_CATEGORIES,
    variables,
  });
};

const listCategoriesByIds = ids =>
  client.query({
    query: LIST_CATEGORIES,
    variables: { ids },
  });

const getCategory = id =>
  client.query({
    query: GET_CATEGORY,
    variables: { id },
  });

const saveCategory = (id, name, language) =>
  client.mutate({
    mutation: SAVE_CATEGORY,
    variables: {
      id,
      name,
      language,
    },
  });

const deleteCategory = id =>
  client.mutate({
    mutation: DELETE_CATEGORY,
    variables: { id },
  });

export {
  listCategories,
  listCategoriesByIds,
  getCategory,
  saveCategory,
  deleteCategory,
};
