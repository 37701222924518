import React from 'react';
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
} from 'react-admin';
import { ActionWrapper, FormWrapper } from 'components/atoms';
import { useCountry } from 'hooks/useCountry';

const SubcategoryForm = props => {
  const { action, history, ...rest } = props;
  const { countryData } = useCountry();

  return (
    <ActionWrapper action={action} title={`${action} Category`} {...rest}>
      <FormWrapper history={history} redirect="list" basepath="/subcategories">
        <SelectInput
          source="language"
          choices={
            action === 'Create'
              ? countryData.country === 'italy'
                ? [{ id: 'it', name: 'Italy' }]
                : [{ id: 'pt', name: 'Brazil' }]
              : [
                  { id: 'pt', name: 'Brazil' },
                  { id: 'it', name: 'Italy' },
                ]
          }
          label="Country"
          options={{ disabled: true }}
          initialValue={countryData.country === 'italy' ? 'it' : 'pt'}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceInput
              label="Parent Category *"
              source="category.id"
              reference="categories"
              filter={{ language: formData.language }}
              validate={required('A parent category is required')}
              defaultValue={null}
              {...rest}
            >
              <SelectInput optionText="name" translateChoice={false} />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <TextInput
          source="name"
          label="Subcategory name"
          validate={required('Subcategory name is required')}
        />
      </FormWrapper>
    </ActionWrapper>
  );
};

export default SubcategoryForm;
