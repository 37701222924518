// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_date-picker-input-wrapper__3KHmo {\n  position: relative;\n  width: 100%; }\n  .styles_date-picker-input-wrapper__3KHmo > div:first-of-type {\n    margin-bottom: 20px;\n    width: 100%;\n    background-color: rgba(0, 0, 0, 0.04);\n    border-top-left-radius: 4px;\n    border-top-right-radius: 4px; }\n\n.styles_label__U2_OM {\n  margin-left: 12px;\n  margin-top: 6px; }\n\n.styles_label-empty-value__2_8a7 {\n  margin-left: 12px;\n  margin-top: 16px; }\n\n.styles_input__1vQmU {\n  padding-left: 12px; }\n", ""]);
// Exports
exports.locals = {
	"date-picker-input-wrapper": "styles_date-picker-input-wrapper__3KHmo",
	"label": "styles_label__U2_OM",
	"label-empty-value": "styles_label-empty-value__2_8a7",
	"input": "styles_input__1vQmU"
};
module.exports = exports;
