import React from 'react';
import { TextInput, required } from 'react-admin';
import { ActionWrapper, FormWrapper } from 'components/atoms';

const PetTypeForm = props => {
  const { action, history, ...rest } = props;

  return (
    <ActionWrapper action={action} title={`${action} pet type`} {...rest}>
      <FormWrapper history={history} redirect="list" basepath="/petTypes">
        <TextInput
          source="name"
          label="Pet type"
          validate={required('Type is required')}
        />
      </FormWrapper>
    </ActionWrapper>
  );
};

export default PetTypeForm;
