import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '& > div': {
      width: '49%',
    },
  },
  image: {
    display: 'flex',
    width: 150,
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#9c2e85',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  hideContent: {
    display: 'none',
  },
  deleteButton: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  passwordInputEditWrapper: {
    '& button': {
      display: 'none',
    },
  },
});
