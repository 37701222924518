import gql from 'graphql-tag';

export const LIST_SUBCATEGORIES = gql`
  query locationSubcategories(
    $ids: [ID!]
    $name: String
    $categoryName: String
    $categoriesIds: [ID!]
    $take: Int
    $skip: Int
    $fieldSort: String
    $order: String
    $language: String
  ) {
    locationSubcategories(
      ids: $ids
      name: $name
      categoryName: $categoryName
      categoriesIds: $categoriesIds
      take: $take
      skip: $skip
      fieldSort: $fieldSort
      order: $order
      language: $language
    ) {
      items {
        id
        name
        language
        createdAt
        updatedAt
        category {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_SUBCATEGORY = gql`
  query locationSubcategory($id: ID!) {
    locationSubcategory(id: $id) {
      id
      name
      language
      category {
        id
        name
      }
    }
  }
`;
