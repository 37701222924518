// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_search-region__2NxnV {\n  width: 250px; }\n", ""]);
// Exports
exports.locals = {
	"search-region": "styles_search-region__2NxnV"
};
module.exports = exports;
