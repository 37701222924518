import gql from 'graphql-tag';

export const DELETE_USER = gql`
  mutation deleteUserAdminPanel($id: ID!) {
    deleteUserAdminPanel(id: $id)
  }
`;

export const DELETE_USER_PHOTO = gql`
  mutation deleteUserPhoto($id: ID!) {
    deleteUserPhoto(id: $id)
  }
`;

export const SAVE_ADMIN_USER = gql`
  mutation saveAdminUser(
    $firstName: String!
    $lastName: String!
    $email: Email!
    $telephone: String!
    $birthDate: Date
    $password: String!
  ) {
    saveAdminUser(
      adminUserInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        telephone: $telephone
        birthDate: $birthDate
        password: $password
      }
    ) {
      id
      firstName
      lastName
      email
      telephone
      birthDate
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation updateAdminUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: Email
    $telephone: String
    $birthDate: Date
    $active: Boolean
    $password: String
    $photo: String
  ) {
    updateAdminUser(
      id: $id
      adminUserInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        telephone: $telephone
        birthDate: $birthDate
        active: $active
        password: $password
        photo: $photo
      }
    ) {
      id
      firstName
      lastName
      email
      telephone
      birthDate
      active
      photo
      createdAt
      updatedAt
    }
  }
`;
