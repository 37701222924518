import React from 'react';
import { Screen } from 'components/templates';
import { CategoryList } from 'components/organisms';
import { CategoryForm } from 'components/organisms';

export const List = props => (
  <Screen header="Partners Categories">
    <CategoryList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Partner Category">
    <CategoryForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Partner Category">
    <CategoryForm action="Create" {...props} />
  </Screen>
);
