import gql from 'graphql-tag';

export const LIST_PET_FURS = gql`
  query petFurs(
    $ids: [ID!]
    $name: String
    $order: String
    $fieldSort: String
    $skip: Int
    $take: Int
    $language: String
  ) {
    petFurs(
      ids: $ids
      name: $name
      order: $order
      fieldSort: $fieldSort
      skip: $skip
      take: $take
      language: $language
    ) {
      items {
        id
        name
        slug
        type {
          id
          name
          slug
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const GET_PET_FUR = gql`
  query petFur($id: ID!) {
    petFur(id: $id) {
      id
      name
      language
      type {
        id
        name
      }
    }
  }
`;
