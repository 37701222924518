import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput, required } from 'react-admin';
import { TextField } from '@material-ui/core';
import { useForm } from 'react-final-form';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import { execute } from 'helpers/promises';

import styles from './styles.module.scss';

const GoogleAutocomplete = ({
  initialValue,
  type,
  onBlur,
  inputRef,
  onSuggestionSelect,
}) => {
  const form = useForm();
  const ref = useRef();
  const [suggestEnabled, setSuggestEnabled] = useState(false);
  const {
    ready,
    suggestions: { status, data },
    value,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ['it', 'br'],
      },
    },
    debounce: 300,
  });

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
      setSuggestEnabled(false);
      clearSuggestions();
    }
  }, [value, initialValue, setValue, clearSuggestions]);

  useOnclickOutside(ref, () => {
    clearSuggestions();
  });

  const handleInput = e => {
    setValue(e.target.value);
    form.change(type, e.target.value);
  };

  const handleFocus = () => {
    setSuggestEnabled(true);
  };

  const handleSelect = ({
    place_id,
    structured_formatting: { main_text },
  }) => async () => {
    setValue(main_text, false);
    form.change(type, main_text);
    clearSuggestions();

    const [[result], error] = await execute(getGeocode({ placeId: place_id }));

    if (error) {
      // eslint-disable-next-line no-console
      console.error(`Error: ${error}`);
      return;
    }

    const { address_components, formatted_address } = result;

    const address = address_components.filter(component =>
      component.types.includes('route'),
    );

    const addressNumber = address_components.filter(component =>
      component.types.includes('street_number'),
    );

    const formattedAddressParts = formatted_address.split(',');

    const sublocality = address_components.find(component =>
      component.types.includes('sublocality'),
    );

    let sublocalityName = '';
    if (sublocality) {
      sublocalityName = sublocality.long_name;
    }

    const locationAddress =
      address.length > 0 && addressNumber.length > 0
        ? `${address[0].long_name}, ${
            addressNumber[0].long_name
          }${sublocalityName && ' - '}${sublocalityName}`
        : formattedAddressParts.length > 3
        ? `${formattedAddressParts[0]}, ${formattedAddressParts[1]}`
        : formattedAddressParts[0];

    if (type === 'name') {
      form.change('address', locationAddress);
    }

    const country = address_components.find(component =>
      component.types.includes('country'),
    );

    const stateAdministrativeLevel = 'administrative_area_level_1';
    const provinceAdministativeLevel = 'administrative_area_level_2';

    const stateOrProvince = address_components.find(component =>
      component.types.includes(
        country.short_name === 'BR'
          ? stateAdministrativeLevel
          : provinceAdministativeLevel,
      ),
    );

    const cityBasedOnStateOrProvince = address_components.find(component => {
      const administrativeLevel = stateOrProvince.types[0];

      return component.types.includes(
        administrativeLevel === stateAdministrativeLevel
          ? 'administrative_area_level_2'
          : 'administrative_area_level_3',
      );
    });

    form.change('city', cityBasedOnStateOrProvince.short_name);
    form.change('province', stateOrProvince.short_name);
    form.change('country', country.short_name);

    const { lat, lng } = await getLatLng(result);

    form.change('latitude', lat);
    form.change('longitude', lng);

    setSuggestEnabled(false);

    onBlur();

    if (onSuggestionSelect) {
      onSuggestionSelect();
    }
  };

  const renderSuggestions = () => {
    if (suggestEnabled) {
      return data.map(suggestion => {
        const {
          id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <li key={id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });
    }
  };

  return (
    <div className={styles['address-wrapper']} ref={ref}>
      <TextField
        required
        className={styles.autocomplete}
        label={type}
        onChange={handleInput}
        disabled={!ready}
        onFocus={handleFocus}
        onBlur={onBlur}
        value={initialValue}
        variant="filled"
        inputRef={inputRef}
        fullWidth
      />
      <TextInput
        className={styles.input}
        source={type}
        label={type}
        validate={required(`${type} is required`)}
        fullWidth
      />
      {status === 'OK' && suggestEnabled && (
        <ul className={styles.suggestions}>{renderSuggestions()}</ul>
      )}
    </div>
  );
};

GoogleAutocomplete.propTypes = {
  onBlur: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  initialValue: PropTypes.string,
  type: PropTypes.string.isRequired,
  onSuggestionSelect: PropTypes.func,
};

GoogleAutocomplete.defaultProps = {
  onBlur: () => null,
  inputRef: () => null,
  initialValue: '',
  onSuggestionSelect: () => null,
};

export default GoogleAutocomplete;
