export const getTelephoneMaskByCountry = language => {
  switch (language) {
    case 'it':
      return '+3\\9 99 99999 999';
    case 'pt':
      return '+55 (99) 9999 9999';
    default:
      return '+3\\9 99 99999 999';
  }
};

export const getMobileMaskByCountry = language => {
  switch (language) {
    case 'it':
      return '+3\\9 999 999 9999';
    case 'pt':
      return '+55 (99) 99999 9999';
    default:
      return '+3\\9 999 999 9999';
  }
};
