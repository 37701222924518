import React from 'react';
import { Screen } from 'components/templates';
import { LocationList, LocationForm } from 'components/organisms';

export const List = props => (
  <Screen header="Partners">
    <LocationList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Partner">
    <LocationForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Partner">
    <LocationForm action="Create" {...props} />
  </Screen>
);
