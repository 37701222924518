import * as React from 'react';
import Button from '@material-ui/core/Button';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import flagBRIcon from '../../../assets/icons/flagBR.svg';
import flagITIcon from '../../../assets/icons/flagIT.svg';
import { useCountry } from '../../../hooks/useCountry';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';

const LocaleSwitcher = () => {
  const { countryData, setCountryData } = useCountry();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = data => {
    if (!isEqual(data, countryData)) {
      setCountryData(data);
      localStorage.setItem('@helpetAdmin:countryData', JSON.stringify(data));
      handleClose();
      history.push('/');
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          src={countryData.country === 'brazil' ? flagBRIcon : flagITIcon}
          alt={countryData.country === 'brazil' ? 'Brazil' : 'Italy'}
          width={24}
          height={24}
          style={{ borderRadius: 2 }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() =>
            handleSelect({ country: 'brazil', language: 'pt', shortName: 'BR' })
          }
        >
          <ListItemIcon>
            <img
              src={flagBRIcon}
              alt="Brazil"
              width={24}
              height={24}
              style={{ borderRadius: 2 }}
            />
          </ListItemIcon>
          <ListItemText>Brazil</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleSelect({ country: 'italy', language: 'it', shortName: 'IT' })
          }
        >
          <ListItemIcon>
            <img
              src={flagITIcon}
              alt="Italy"
              width={24}
              height={24}
              style={{ borderRadius: 2 }}
            />
          </ListItemIcon>
          <ListItemText>Italy</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LocaleSwitcher;
