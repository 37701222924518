import React from 'react';
import { List, DateField, TextField } from 'react-admin';
import { getDateFormat } from 'helpers/date';
import { Datagrid } from 'components/atoms';
import { SearchFilter } from 'components/molecules';
import { useCountry } from 'hooks/useCountry';

const PetFurList = props => {
  const { countryData } = useCountry();

  return (
    <List
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filter={{ language: countryData.language }}
      filters={<SearchFilter />}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Pet breed" />
        <TextField source="type.name" label="Pet type" />
        <DateField
          source="updatedAt"
          label="Updated at"
          options={getDateFormat()}
        />
        <DateField
          source="createdAt"
          label="Created at"
          options={getDateFormat()}
        />
      </Datagrid>
    </List>
  );
};

export default PetFurList;
