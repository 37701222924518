import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory as createHistory } from 'history';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const history = createHistory();

ReactDOM.render(<App history={history} />, document.getElementById('root'));

serviceWorker.register();
