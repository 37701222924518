// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_array-input-wrapper__1jU0l {\n  position: relative;\n  width: 100%; }\n  .styles_array-input-wrapper__1jU0l > div:first-of-type {\n    width: 100% !important; }\n  .styles_array-input-wrapper__1jU0l .styles_weekdays-container__3yqDV > div {\n    margin-top: 10px; }\n", ""]);
// Exports
exports.locals = {
	"array-input-wrapper": "styles_array-input-wrapper__1jU0l",
	"weekdays-container": "styles_weekdays-container__3yqDV"
};
module.exports = exports;
