import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import {
  required,
  ChipField,
  useDataProvider,
  SelectArrayInput,
} from 'react-admin';
import { useCountry } from 'hooks/useCountry';

import { TransferListInput } from 'components/atoms';

import styles from './styles.module.scss';

const CategoriesInputs = ({ currentCategoriesIds }) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const { countryData } = useCountry();

  const hasCategoriesIds = useCallback(
    ids => Array.isArray(ids) && ids.length > 0 && typeof ids[0] === 'string',
    [],
  );

  const getSubcategoriesChoices = useCallback(
    categoriesIds => {
      if (typeof categoriesIds[0] === 'string') {
        dataProvider
          .getAll('subcategories', { categoriesIds })
          .then(({ data }) => {
            setSubcategories(data);
          })
          .catch(error => {
            setSubcategories([]);
          });
      }
    },
    [dataProvider],
  );

  useEffect(() => {
    dataProvider
      .getAll('categories')
      .then(({ data }) => {
        const categoriesChoices = data.filter(
          category => category.language === countryData.language,
        );
        setCategories(categoriesChoices);
      })
      .catch(error => {
        setCategories([]);
      });
  }, [dataProvider, countryData]);

  useEffect(() => {
    if (hasCategoriesIds(currentCategoriesIds)) {
      getSubcategoriesChoices(currentCategoriesIds);
    }
  }, [currentCategoriesIds, hasCategoriesIds, getSubcategoriesChoices]);

  const handleCategoriesChange = categoriesIds => {
    if (hasCategoriesIds(categoriesIds)) {
      getSubcategoriesChoices(categoriesIds);
      form.change('subcategories', []);
    }
  };

  return (
    <>
      <TransferListInput
        label="Categories"
        source="categories"
        leftColumnTitle="All Categories"
        rightColumnTitle="Partner Categories *"
        onChange={handleCategoriesChange}
        sourceItems={
          hasCategoriesIds(currentCategoriesIds)
            ? categories.filter(category => {
                return !currentCategoriesIds.find(id => id === category.id);
              })
            : categories
        }
        selectedItems={
          hasCategoriesIds(currentCategoriesIds) && categories.length > 0
            ? currentCategoriesIds.map(categoryId =>
                categories.find(category => category.id === categoryId),
              )
            : []
        }
        validate={required('Choose at least one category')}
      />
      <div className={styles['input-wrapper']}>
        <SelectArrayInput
          label="Subcategories"
          source="subcategories"
          optionText="name"
          choices={subcategories}
          translateChoice={false}
        >
          <ChipField source="name" />
        </SelectArrayInput>
      </div>
    </>
  );
};

CategoriesInputs.propTypes = {
  currentCategoriesIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default CategoriesInputs;
