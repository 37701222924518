import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Cancel } from '@material-ui/icons';

const CancelButton = ({ onClick, children }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<Cancel />}
      style={{ marginLeft: '20px' }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CancelButton;
