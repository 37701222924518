import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {
  ActionWrapper,
  FormWrapper,
  ImageInput,
  TelephoneInput,
} from 'components/atoms';
import {
  AddressInputs,
  CategoriesInputs,
  HoursArrayInput,
} from 'components/molecules';
import { useCountry } from 'hooks/useCountry';
import {
  getTelephoneMaskByCountry,
  getMobileMaskByCountry,
} from 'helpers/language';

const LocationForm = props => {
  const { action, history, ...rest } = props;
  const { countryData } = useCountry();
  const { language } = countryData;

  const componentAfterName = (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <TextInput
            source="responsibleVeterinary"
            label="Responsible Veterinary"
            fullWidth
          />
          <ImageInput source="picture" filename={`${formData.id}-banner.jpg`} />
        </>
      )}
    </FormDataConsumer>
  );

  return (
    <ActionWrapper action={action} title={`${action} Partner`} {...rest}>
      <FormWrapper history={history} redirect="list" basepath="/locations">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <AddressInputs
                currentAddress={formData.address}
                currentName={formData.name}
                currentCountry={formData.country}
                componentsAfter={new Map([['name', componentAfterName]])}
              />
              <CategoriesInputs currentCategoriesIds={formData.categories} />
            </>
          )}
        </FormDataConsumer>
        <ArrayInput source="emails" label="E-mails">
          <SimpleFormIterator>
            <TextInput source="email" label="Email" type="e-mail" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="landlinePhones" label="Telephones">
          <SimpleFormIterator>
            {language !== 'it' ? (
              <TelephoneInput
                source="phone"
                label="Telephone"
                mask={getTelephoneMaskByCountry(language)}
                parse={value => value.replace(/[(,)]|\s/g, '').trim()}
              />
            ) : action === 'Create' ? (
              <TelephoneInput
                source="phone"
                label="Telephone"
                mask={getTelephoneMaskByCountry(language)}
                parse={value => value.replace(/[(,)]|\s/g, '').trim()}
              />
            ) : (
              <TextInput source="phone" label="Telephone" />
            )}
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="mobilePhones" label="Mobile phones">
          <SimpleFormIterator>
            {language !== 'it' ? (
              <TelephoneInput
                source="phone"
                label="Mobile phone"
                mask={getMobileMaskByCountry(language)}
                parse={value => value.replace(/[(,)]|\s/g, '').trim()}
              />
            ) : action === 'Create' ? (
              <TelephoneInput
                source="phone"
                label="Mobile phone"
                mask={getMobileMaskByCountry(language)}
                parse={value => value.replace(/[(,)]|\s/g, '').trim()}
              />
            ) : (
              <TextInput source="phone" label="Mobile phone" />
            )}
            <BooleanInput source="whatsapp" label="Whatsapp" />
          </SimpleFormIterator>
        </ArrayInput>
        <HoursArrayInput source="hours" />
        <TextInput source="siteUrl" label="Site URL" type="url" />
        <TextInput source="facebookUrl" label="Facebook URL" type="url" />
        <TextInput source="instagramUrl" label="Instagram URL" type="url" />
        <TextInput source="description" label="More info" multiline />
        <RichTextInput source="internalNotes" label="Internal notes" />
      </FormWrapper>
    </ActionWrapper>
  );
};

LocationForm.propTypes = {
  action: PropTypes.string.isRequired,
};

export default LocationForm;
