import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import styles from './styles.module.scss';

const TransferListInput = props => {
  const [checked, setChecked] = useState([]);
  const [sourceItems, setSourceItems] = useState(props.sourceItems);
  const [selectedItems, setSelectedItems] = useState(props.selectedItems);

  useEffect(() => {
    setSourceItems(props.sourceItems);
    setSelectedItems(props.selectedItems);
  }, [props.sourceItems, props.selectedItems]);

  const {
    input: { onChange: onSave },
  } = useInput(props);

  const intersection = (a, b) =>
    a.filter(itemA => Boolean(b.find(itemB => itemA.id === itemB.id)));
  const not = (a, b) =>
    a.filter(itemA => !Boolean(b.find(itemB => itemA.id === itemB.id)));
  const union = (a, b) => [...a, ...not(b, a)];

  const sourceItemsChecked = intersection(checked, sourceItems);
  const selectedItemsChecked = intersection(checked, selectedItems);

  const { onChange, leftColumnTitle, rightColumnTitle } = props;

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedSourceItems = () => {
    const newSelectedItems = not(selectedItems, selectedItemsChecked);
    const categoriesIds = newSelectedItems.map(category => category.id);

    setSourceItems(sourceItems.concat(selectedItemsChecked));
    setSelectedItems(newSelectedItems);
    setChecked(not(checked, selectedItemsChecked));
    onChange(categoriesIds);
    onSave(categoriesIds);
  };

  const handleCheckedSelectedItems = () => {
    const newSelectedItems = selectedItems.concat(sourceItemsChecked);
    const categoriesIds = newSelectedItems.map(category => category.id);

    setSelectedItems(newSelectedItems);
    setSourceItems(not(sourceItems, sourceItemsChecked));
    setChecked(not(checked, sourceItemsChecked));
    onChange(categoriesIds);
    onSave(categoriesIds);
  };

  const customList = (title, items) => {
    return (
      !!items && (
        <Card>
          <CardHeader
            className={styles['transfer-list-card-header']}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={
                  numberOfChecked(items) === items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items) !== items.length &&
                  numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{ 'aria-label': 'All items selected' }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
          />
          <Divider />
          <List
            className={styles['transfer-list-container-list']}
            dense
            component="div"
            role="list"
          >
            {items.map(item => {
              const labelId = !!item
                ? `transfer-list-all-item-${item.id}-label`
                : '';
              return (
                !!item && (
                  <ListItem
                    key={item.id}
                    role="listitem"
                    button
                    onClick={handleToggle(item)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={Boolean(
                          checked.find(
                            checkedItem => checkedItem.id === item.id,
                          ),
                        )}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={item.name} />
                  </ListItem>
                )
              );
            })}
            <ListItem />
          </List>
        </Card>
      )
    );
  };

  return (
    <div className={styles['input-wrapper']}>
      <Grid
        container
        spacing={2}
        justifycontent="center"
        alignItems="center"
        className={styles['grid-root']}
      >
        <Grid item>{customList(leftColumnTitle, sourceItems)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={styles['transfer-list-button']}
              onClick={handleCheckedSelectedItems}
              disabled={sourceItemsChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={styles['transfer-list-button']}
              onClick={handleCheckedSourceItems}
              disabled={selectedItemsChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(rightColumnTitle, selectedItems)}</Grid>
      </Grid>
    </div>
  );
};

TransferListInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  leftColumnTitle: PropTypes.string.isRequired,
  rightColumnTitle: PropTypes.string.isRequired,
  sourceItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TransferListInput;
