import React from 'react';
import { Screen } from 'components/templates';
import { PetBreedList, PetBreedForm } from 'components/organisms';

export const List = props => (
  <Screen header="Pet breeds">
    <PetBreedList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Pet breed">
    <PetBreedForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Pet breed">
    <PetBreedForm action="Create" {...props} />
  </Screen>
);
