import React, { useState } from 'react';
import {
  DeleteButton as DefaultDeleteButton,
  Confirm,
  useDelete,
  useRefresh,
} from 'react-admin';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  deleteButton: {
    color: '#F44336',
  },
});

const DeleteButton = ({ showTitle, record, resource, basePath }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [openModal, setOpenModal] = useState(false);
  const [deleteResource, { loading }] = useDelete(resource, record.id, null, {
    onSuccess: () => refresh(),
  });

  return showTitle ? (
    <DefaultDeleteButton
      undoable={false}
      confirmTitle={`Delete: "${record.name}"`}
      record={record}
      resource={resource}
      basePath={basePath}
    />
  ) : (
    <>
      <Button
        variant="text"
        onClick={() => setOpenModal(true)}
        className={classes.deleteButton}
      >
        <Delete />
      </Button>
      <Confirm
        loading={loading}
        isOpen={openModal}
        title={`Delete: "${record.name}"`}
        content="Are you sure you want to delete this item?"
        onClose={() => setOpenModal(false)}
        onConfirm={() => deleteResource()}
      />
    </>
  );
};

DeleteButton.propTypes = {
  showTitle: PropTypes.string,
};

DeleteButton.defaultProps = {
  showTitle: false,
};

export default DeleteButton;
