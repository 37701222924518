import {
  listSubcategories,
  listSubcategoriesByIds,
  getSubcategory,
  saveSubcategory,
  deleteSubcategory,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q, categoryName, language } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      locationSubcategories: { items, total },
    },
  } = await listSubcategories(
    perPage,
    skip,
    field,
    order,
    categoryName,
    undefined,
    q,
    language,
  );

  return { data: items, total };
};

export const getAll = async params => {
  const categoriesIds = params.categoriesIds || undefined;

  const {
    data: {
      locationSubcategories: { items },
    },
  } = await listSubcategories(100, 0, 'name', 'ASC', undefined, categoriesIds);

  return { data: items };
};

export const getAllCategories = async () => {
  const {
    data: {
      locationSubcategories: { items },
    },
  } = await listSubcategories(100, 0, 'category.name', 'ASC');

  return { data: items };
};

export const getMany = async params => {
  const ids = params.ids.map(item => item);

  const {
    data: {
      locationSubcategories: { items },
    },
  } = await listSubcategoriesByIds(ids);

  return { data: items };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { locationSubcategory },
  } = await getSubcategory(id);

  return { data: locationSubcategory };
};

export const create = async params => {
  const {
    data: { name, language, category },
  } = params;

  const {
    data: { saveLocationSubcategory },
  } = await saveSubcategory(null, name, language, category.id);

  return { data: { id: saveLocationSubcategory.id } };
};

export const update = async params => {
  const {
    data: { id, name, language, category },
  } = params;

  const {
    data: { saveLocationSubcategory },
  } = await saveSubcategory(id, name, language, category.id);

  return { data: { id: saveLocationSubcategory.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deleteSubcategory(id);

  if (deleted) return { data: { id } };
  else return null;
};
