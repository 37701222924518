import React from 'react';

import { AdminUserList, AdminUserForm } from 'components/organisms';
import { Screen } from 'components/templates';

export const List = props => (
  <Screen header="List Users">
    <AdminUserList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Edit User">
    <AdminUserForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Create User">
    <AdminUserForm action="Create" {...props} />
  </Screen>
);
