import {
  checkExistence,
  listLocations,
  getLocation,
  saveOneLocation,
  deleteLocation,
  uploadBanner,
} from './actions';

export const locationExists = async params => {
  const { name, address, id } = params;

  const {
    data: { locationExists },
  } = await checkExistence(name, address, id);

  return locationExists;
};

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const {
    q,
    regionName,
    categoryName,
    subcategoryName,
    editedByName,
    country,
  } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      locations: { items, total },
    },
  } = await listLocations(
    perPage,
    skip,
    field,
    order,
    categoryName,
    subcategoryName,
    editedByName,
    q,
    regionName,
    country,
  );

  return { data: items, total };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { location },
  } = await getLocation(id);

  const emailsArray = [];
  if (location.emails)
    location.emails.forEach(element => {
      emailsArray.push({ email: element });
    });

  const landlinePhonesArray = [];
  if (location.landlinePhones)
    location.landlinePhones.forEach(element => {
      landlinePhonesArray.push({ phone: element });
    });

  const mobilePhonesArray = [];
  if (location.mobilePhones)
    location.mobilePhones.forEach(element => {
      mobilePhonesArray.push({
        phone: element.phone,
        whatsapp: element.whatsapp,
      });
    });

  const hoursArray = [];
  if (location.hours)
    location.hours.forEach(element => {
      hoursArray.push({ hour: element });
    });

  const categoriesIds = location.categories.map(category => category.id);
  const subcategoriesIds = location.subcategories.map(
    subcategory => subcategory.id,
  );

  const longitude = location.geoLocation.coordinates[0];
  const latitude = location.geoLocation.coordinates[1];

  const locationData = {
    ...location,
    latitude,
    longitude,
    landlinePhones: landlinePhonesArray || null,
    mobilePhones: mobilePhonesArray || null,
    categories: categoriesIds || null,
    subcategories: subcategoriesIds || null,
    emails: emailsArray || null,
    picture: {
      src: location.banner,
    },
  };

  return { data: locationData };
};

export const create = async params => {
  const {
    data: {
      name,
      address,
      city,
      province,
      country,
      landlinePhones,
      mobilePhones,
      categories,
      subcategories,
      emails,
      latitude,
      longitude,
      description,
      siteUrl,
      facebookUrl,
      instagramUrl,
      internalNotes,
      hours,
      responsibleVeterinary,
      picture,
    },
  } = params;

  const landlinePhonesArray = landlinePhones
    ? landlinePhones.map(phone => phone.phone)
    : [];
  const mobilePhonesArray = mobilePhones
    ? mobilePhones.map(mobile => {
        return {
          phone: mobile.phone,
          whatsapp: mobile.whatsapp || false,
        };
      })
    : [];

  const emailsArray = emails ? emails.map(email => email.email) : [];
  const hoursArray = hours
    ? hours.map(hour => ({
        openingTime: new Date(
          hour.openingTime instanceof Date ? hour.openingTime : Date.now(),
        )
          .toTimeString()
          .slice(0, 5),
        closingTime: new Date(
          hour.closingTime instanceof Date ? hour.closingTime : Date.now(),
        )
          .toTimeString()
          .slice(0, 5),
        daysOfTheWeek: hour.daysOfTheWeek
          ? [1, 2, 3, 4, 5, 6, 7].map(day =>
              Boolean(hour.daysOfTheWeek.find(wday => wday === day)),
            )
          : [],
      }))
    : [];

  const banner = await upload(picture);

  const {
    data: { saveLocation },
  } = await saveOneLocation(
    null,
    name,
    address,
    city,
    province,
    country,
    landlinePhonesArray,
    mobilePhonesArray,
    categories,
    subcategories,
    emailsArray,
    latitude.toString(),
    longitude.toString(),
    description,
    siteUrl,
    facebookUrl,
    instagramUrl,
    internalNotes,
    hoursArray,
    responsibleVeterinary,
    banner,
  );

  return { data: { id: saveLocation.id } };
};

export const update = async params => {
  const {
    data: {
      id,
      name,
      address,
      city,
      province,
      country,
      landlinePhones,
      mobilePhones,
      categories,
      subcategories,
      emails,
      latitude,
      longitude,
      description,
      siteUrl,
      facebookUrl,
      instagramUrl,
      internalNotes,
      hours,
      responsibleVeterinary,
      picture,
    },
  } = params;

  const landlinePhonesArray = landlinePhones
    ? landlinePhones.map(phone => phone.phone)
    : [];
  const mobilePhonesArray = mobilePhones
    ? mobilePhones.map(mobile => {
        return {
          phone: mobile.phone,
          whatsapp: mobile.whatsapp || false,
        };
      })
    : [];

  const emailsArray = emails ? emails.map(email => email.email) : [];
  const hoursArray = hours
    ? hours.map(hour => ({
        openingTime: new Date(
          hour.openingTime instanceof Date ? hour.openingTime : Date.now(),
        )
          .toTimeString()
          .slice(0, 5),
        closingTime: new Date(
          hour.closingTime instanceof Date ? hour.closingTime : Date.now(),
        )
          .toTimeString()
          .slice(0, 5),
        daysOfTheWeek: hour.daysOfTheWeek
          ? [1, 2, 3, 4, 5, 6, 7].map(day =>
              Boolean(hour.daysOfTheWeek.find(wday => wday === day)),
            )
          : [],
      }))
    : [];

  const banner = await upload(picture, 'update');

  const {
    data: { saveLocation },
  } = await saveOneLocation(
    id,
    name,
    address,
    city,
    province,
    country,
    landlinePhonesArray,
    mobilePhonesArray,
    categories,
    subcategories,
    emailsArray,
    latitude.toString(),
    longitude.toString(),
    description,
    siteUrl,
    facebookUrl,
    instagramUrl,
    internalNotes,
    hoursArray,
    responsibleVeterinary,
    banner,
  );

  return { data: { id: saveLocation.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deleteLocation(id);

  if (deleted) return { data: { id } };
  else return null;
};

const upload = async (picture, type = 'create') => {
  if (picture && picture.src) {
    try {
      const result = await uploadBanner(picture);
      return result.data.upload;
    } catch {
      return undefined;
    }
  }

  return type === 'create' ? undefined : '';
};
