import client from 'services/graphql/client';
import { LIST_SUBCATEGORIES, GET_SUBCATEGORY } from './queries';
import { SAVE_SUBCATEGORY, DELETE_SUBCATEGORY } from './mutations';

const listSubcategories = (
  take,
  skip,
  field,
  order,
  categoryName,
  categoriesIds,
  q,
  language,
) => {
  const variables = {
    take,
    skip,
    fieldSort: field,
    order,
  };

  if (categoryName) variables.categoryName = categoryName;

  if (categoriesIds) variables.categoriesIds = categoriesIds;

  if (q) variables.name = q;

  if (language) variables.language = language;

  return client.query({
    query: LIST_SUBCATEGORIES,
    variables,
  });
};

const listSubcategoriesByIds = ids =>
  client.query({
    query: LIST_SUBCATEGORIES,
    variables: { ids },
  });

const getSubcategory = id =>
  client.query({
    query: GET_SUBCATEGORY,
    variables: { id },
  });

const saveSubcategory = (id, name, language, categoryId) =>
  client.mutate({
    mutation: SAVE_SUBCATEGORY,
    variables: {
      id,
      name,
      language,
      categoryId,
    },
  });

const deleteSubcategory = id =>
  client.mutate({
    mutation: DELETE_SUBCATEGORY,
    variables: { id },
  });

export {
  listSubcategories,
  listSubcategoriesByIds,
  getSubcategory,
  saveSubcategory,
  deleteSubcategory,
};
