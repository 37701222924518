import gql from 'graphql-tag';

export const LOCATION_EXISTS = gql`
  query locationExists($name: String!, $address: String!, $id: ID) {
    locationExists(name: $name, address: $address, id: $id)
  }
`;

export const LIST_LOCATIONS = gql`
  query locations(
    $name: String
    $take: Int
    $skip: Int
    $fieldSort: String
    $order: String
    $editedByName: String
    $regionName: String
    $categoryName: String
    $subcategoryName: String
    $country: String
  ) {
    locations(
      name: $name
      take: $take
      skip: $skip
      fieldSort: $fieldSort
      order: $order
      editedByName: $editedByName
      regionName: $regionName
      categoryName: $categoryName
      subcategoryName: $subcategoryName
      country: $country
    ) {
      items {
        id
        name
        address
        city
        province
        country
        createdAt
        updatedAt
        mobilePhones {
          phone
          whatsapp
        }
        landlinePhones
        emails
        categories {
          id
          name
        }
        logs {
          name
        }
        hours {
          openingTime
          closingTime
        }
        internalNotes
      }
      total
    }
  }
`;

export const GET_LOCATION = gql`
  query location($id: ID!) {
    location(id: $id) {
      id
      name
      address
      city
      province
      country
      categories {
        id
        name
      }
      subcategories {
        id
        name
      }
      geoLocation {
        coordinates
      }
      emails
      responsibleVeterinary
      landlinePhones
      mobilePhones {
        phone
        whatsapp
      }
      siteUrl
      facebookUrl
      instagramUrl
      description
      internalNotes
      hours {
        openingTime
        closingTime
        daysOfTheWeek
      }
      banner
    }
  }
`;
