import {
  listPetTypes,
  listPetTypesByIds,
  getPetType,
  saveOnePetType,
  deletePetType,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      petTypes: { items, total },
    },
  } = await listPetTypes(perPage, skip, field, order, q);

  return { data: items, total };
};

export const getMany = async params => {
  const ids = params.ids.map(item => item);

  const {
    data: {
      petTypes: { items },
    },
  } = await listPetTypesByIds(ids);

  return { data: items };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { petType },
  } = await getPetType(id);

  return { data: petType };
};

export const create = async params => {
  const {
    data: { name },
  } = params;

  const {
    data: { savePetType },
  } = await saveOnePetType(null, name);

  return { data: { id: savePetType.id } };
};

export const update = async params => {
  const {
    data: { id, name },
  } = params;

  const {
    data: { savePetType },
  } = await saveOnePetType(id, name);

  return { data: { id: savePetType.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deletePetType(id);

  if (deleted) return { data: { id } };
  else return null;
};
