import React, { useState, createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocationIcon from '@material-ui/icons/LocationOn';
import PetsIcon from '@material-ui/icons/Pets';
import UsersIcon from '@material-ui/icons/Group';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MedicalRecordIcon from '@material-ui/icons/LocalHospital';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { capitalize } from 'helpers/string';

const Menu = ({ onMenuClick, logout }) => {
  const [locationOpen, toggleLocationOpen] = useState(true);
  const [petOpen, togglePetOpen] = useState(true);
  const [medicalRecordOpen, toggleMedicalRecordOpen] = useState(true);
  const [userOpen, toggleUserOpen] = useState(true);
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const locationsResources = [];
  const petsResources = [];
  const medicalRecordResources = [];
  const usersResources = [];

  const restResources = resources.filter(resource => {
    const partnersCategories = ['locations', 'categories', 'subcategories'];
    const petCategories = ['petFurs', 'petTypes', 'petBreeds'];
    const medicalRecordsCategories = ['vaccines'];
    const usersCategories = ['adminUsers', 'appUsers'];

    if (partnersCategories.indexOf(resource.name) > -1) {
      locationsResources.push(resource);
      return false;
    }

    if (petCategories.indexOf(resource.name) > -1) {
      petsResources.push(resource);
      return false;
    }

    if (medicalRecordsCategories.indexOf(resource.name) > -1) {
      medicalRecordResources.push(resource);
      return false;
    }

    if (usersCategories.indexOf(resource.name) > -1) {
      usersResources.push(resource);
      return false;
    }

    return true;
  });

  return (
    <div>
      <MenuItemLink
        key="dashboard"
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <ListItem button onClick={() => togglePetOpen(!petOpen)}>
        <ListItemIcon style={{ minWidth: '40px' }}>
          <PetsIcon />
        </ListItemIcon>
        <ListItemText primary="Pets" />
        {petOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={petOpen}
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: '20px' }}
      >
        <List component="div" disablePadding>
          {petsResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={capitalize(
                (resource.options && resource.options.label) || resource.name,
              )}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={() => toggleLocationOpen(!locationOpen)}>
        <ListItemIcon style={{ minWidth: '40px' }}>
          <LocationIcon />
        </ListItemIcon>
        <ListItemText primary="Locations" />
        {locationOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={locationOpen}
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: '20px' }}
      >
        <List component="div" disablePadding>
          {locationsResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={capitalize(
                (resource.options && resource.options.label) || resource.name,
              )}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={() => toggleMedicalRecordOpen(!medicalRecordOpen)}
      >
        <ListItemIcon style={{ minWidth: '40px' }}>
          <MedicalRecordIcon />
        </ListItemIcon>
        <ListItemText primary="Medical Records" />
        {medicalRecordOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={medicalRecordOpen}
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: '20px' }}
      >
        <List component="div" disablePadding>
          {medicalRecordResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={capitalize(
                (resource.options && resource.options.label) || resource.name,
              )}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        </List>
      </Collapse>
      <ListItem button onClick={() => toggleUserOpen(!userOpen)}>
        <ListItemIcon style={{ minWidth: '40px' }}>
          <UsersIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
        {userOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={userOpen}
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: '20px' }}
      >
        <List component="div" disablePadding>
          {usersResources.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={capitalize(
                (resource.options && resource.options.label) || resource.name,
              )}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        </List>
      </Collapse>

      {restResources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={capitalize(
            (resource.options && resource.options.label) || resource.name,
          )}
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && logout}
    </div>
  );
};

export default withRouter(Menu);
