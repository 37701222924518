import React from 'react';
import { Screen } from 'components/templates';
import { SubcategoryList, SubcategoryForm } from 'components/organisms';

export const List = props => (
  <Screen header="Partners Subcategories">
    <SubcategoryList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Partner Subcategory">
    <SubcategoryForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Partner Subcategory">
    <SubcategoryForm action="Create" {...props} />
  </Screen>
);
