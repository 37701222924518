import {
  listCategories,
  listCategoriesByIds,
  getCategory,
  saveCategory,
  deleteCategory,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q, language } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      locationCategories: { items, total },
    },
  } = await listCategories(perPage, skip, field, order, q, language);

  return { data: items, total };
};

export const getAll = async () => {
  const {
    data: {
      locationCategories: { items },
    },
  } = await listCategories(100, 0, 'name', 'ASC', undefined);

  return { data: items };
};

export const getMany = async params => {
  const ids = params.ids.map(item => item);

  const {
    data: {
      locationCategories: { items },
    },
  } = await listCategoriesByIds(ids);

  return { data: items };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { locationCategory },
  } = await getCategory(id);

  return { data: locationCategory };
};

export const create = async params => {
  const {
    data: { name, language },
  } = params;

  const {
    data: { saveLocationCategory },
  } = await saveCategory(null, name, language);

  return { data: { id: saveLocationCategory.id } };
};

export const update = async params => {
  const {
    data: { id, name, language },
  } = params;

  const {
    data: { saveLocationCategory },
  } = await saveCategory(id, name, language);

  return { data: { id: saveLocationCategory.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deleteCategory(id);

  if (deleted) return { data: { id } };
  else return null;
};
