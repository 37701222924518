import React, { useState, useEffect } from 'react';
import { List, DateField, TextField, useDataProvider } from 'react-admin';
import { getDateFormat } from 'helpers/date';
import { Datagrid } from 'components/atoms';
import { SearchFilter } from 'components/molecules';
import { useCountry } from 'hooks/useCountry';

const SubcategoryList = props => {
  const { countryData } = useCountry();
  const dataProvider = useDataProvider();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dataProvider
      .getAllCategories('subcategories')
      .then(({ data }) => {
        const categories = data.map(item => item.category);
        const distinctCategories = [
          ...new Set(
            categories.filter(item => ({
              id: item.id,
              name: item.name,
            })),
          ),
        ];
        setCategories(distinctCategories);
      })
      .catch(error => {
        setCategories([]);
      });
  }, [dataProvider]);

  const autocompleteInputs = [
    {
      label: 'Parent Category',
      source: 'categoryName',
      optionField: 'name',
      choices: categories,
    },
  ];

  return (
    <List
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filter={{ language: countryData.language }}
      filters={<SearchFilter autocomplete={autocompleteInputs} />}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Subcategory" />
        <TextField source="category.name" label="Parent Category" />
        <DateField
          source="updatedAt"
          label="Updated at"
          options={getDateFormat()}
        />
        <DateField
          source="createdAt"
          label="Created at"
          options={getDateFormat()}
        />
      </Datagrid>
    </List>
  );
};

export default SubcategoryList;
