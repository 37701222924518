import React, { useEffect } from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  CheckboxGroupInput,
} from 'react-admin';
import { TimeInput } from 'components/atoms';
import { formatDateToTimeInput } from 'helpers/date';

import styles from './styles.module.scss';

const HoursArrayInput = props => {
  useEffect(() => {
    if (props.record[props.source]) {
      const hours = [...props.record[props.source]];
      props.record[props.source] = hours.map(item => ({
        openingTime: formatDateToTimeInput(item.openingTime),
        closingTime: formatDateToTimeInput(item.closingTime),
        daysOfTheWeek: formatDataToDaysOfTheWeekInput(item.daysOfTheWeek),
      }));
    }
  }, [props.record, props.source]);

  const weekDays = [
    { id: 1, name: 'Sunday' },
    { id: 2, name: 'Monday' },
    { id: 3, name: 'Tuesday' },
    { id: 4, name: 'Wednesday' },
    { id: 5, name: 'Thursday' },
    { id: 6, name: 'Friday' },
    { id: 7, name: 'Saturday' },
  ];

  const formatDataToDaysOfTheWeekInput = ids => {
    const selectedIds = [];
    if (ids && Array.isArray(ids) && typeof ids[0] === 'boolean') {
      ids.forEach((item, index) => {
        if (item) selectedIds.push(index + 1);
      });

      return selectedIds;
    }

    return ids;
  };

  return (
    <div className={styles['array-input-wrapper']}>
      <ArrayInput {...props}>
        <SimpleFormIterator>
          <TimeInput
            source="openingTime"
            label="Opening time"
            format={formatDateToTimeInput}
          />
          <TimeInput
            source="closingTime"
            label="Closing time"
            format={formatDateToTimeInput}
          />
          <CheckboxGroupInput
            label="Days of the week"
            source="daysOfTheWeek"
            optionText="name"
            optionValue="id"
            choices={weekDays}
            format={formatDataToDaysOfTheWeekInput}
            className={styles['weekdays-container']}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

export default HoursArrayInput;
