import React, { useState } from 'react';
import {
  TextInput,
  BooleanInput,
  Confirm,
  ImageField,
  required,
  minLength,
} from 'react-admin';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import subYears from 'date-fns/subYears';
import * as AppUsers from '../../../providers/AppUsers';

import { ActionWrapper, FormWrapper, DatePickerInput } from 'components/atoms';

import { useStyles } from './styles';

const AppUserForm = props => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const validateOnCreation = values => {
    const errors = {};

    if (values.password !== values.confirmPassword) {
      errors.password = "These inputs aren't equal";
      errors.confirmPassword = "These inputs aren't equal";
    }

    return errors;
  };

  const { action, history, ...rest } = props;

  const deletePhoto = async () => {
    await AppUsers.deletePhoto(props.id);
    window.location.reload();
  };

  return (
    <ActionWrapper title={`${action} User`} {...{ ...rest, action }}>
      <FormWrapper
        history={history}
        redirect="list"
        basepath="/appUsers"
        validate={validateOnCreation}
      >
        <ImageField
          addLabel={false}
          source="photo"
          className={action === 'Edit' ? classes.image : classes.hideContent}
        />
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          className={
            action === 'Edit' ? classes.deleteButton : classes.hideContent
          }
          onClick={() => setOpenModal(true)}
        >
          Delete Image
        </Button>
        <Confirm
          isOpen={openModal}
          title={'Delete photo'}
          content="Are you sure you want to delete this user photo? This page will be reloaded"
          onClose={() => setOpenModal(false)}
          onConfirm={() => deletePhoto()}
        />
        <div className={classes.row}>
          <TextInput
            source="firstName"
            label="First name"
            validate={required('First name is required')}
          />
          <TextInput
            source="lastName"
            label="Last name"
            validate={required('Last name is required')}
          />
        </div>
        <TextInput
          source="email"
          label="Email"
          type="e-mail"
          style={{ width: '100%' }}
          inputProps={{ autocomplete: 'off' }}
          validate={required('Email is required')}
        />
        <div className={classes.row}>
          <TextInput
            source="telephone"
            label="Phone number"
            type="phone"
            validate={[required('Phone number is required'), minLength(14)]}
          />
          <DatePickerInput
            source="birthDate"
            label="Birthdate"
            format="dd/MM/yyyy"
            initialFocusedDate={subYears(Date.now() - 100, 18)}
            maxDate={subYears(Date.now(), 18)}
          />
        </div>
        <BooleanInput
          label="Active"
          source="active"
          className={action === 'Create' && classes.hideContent}
        />
      </FormWrapper>
    </ActionWrapper>
  );
};

export default AppUserForm;
