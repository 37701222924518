import client from 'services/graphql/client';

import { LIST_USERS, LIST_USER } from './queries';
import {
  DELETE_USER,
  DELETE_USER_PHOTO,
  SAVE_ADMIN_USER,
  UPDATE_ADMIN_USER,
} from './mutations';

const listUsers = ({
  users: { ids, isAdmin, firstName, lastName, email },
  filter: { fieldSort, order },
  pagination: { skip = 0, take = 50 },
}) =>
  client.query({
    query: LIST_USERS,
    variables: {
      skip,
      take,
      firstName,
      lastName,
      email,
      isAdmin,
      order,
      fieldSort,
      ids,
    },
  });

const listOneUser = ({ id }) =>
  client.query({
    query: LIST_USER,
    variables: {
      id,
    },
  });

const deleteUser = id =>
  client.mutate({
    mutation: DELETE_USER,
    variables: {
      id,
    },
  });

const deleteUserPhoto = id =>
  client.mutate({
    mutation: DELETE_USER_PHOTO,
    variables: {
      id,
    },
  });

const saveOneAdminUser = ({
  firstName,
  lastName,
  email,
  telephone,
  birthDate,
  password,
}) =>
  client.mutate({
    mutation: SAVE_ADMIN_USER,
    variables: {
      firstName,
      lastName,
      email,
      telephone,
      birthDate,
      password,
    },
  });

const updateAdminUser = ({
  id,
  firstName,
  lastName,
  email,
  telephone,
  birthDate,
  active,
  password,
  photo,
}) =>
  client.mutate({
    mutation: UPDATE_ADMIN_USER,
    variables: {
      id,
      firstName,
      lastName,
      email,
      telephone,
      birthDate,
      active,
      password,
      photo,
    },
  });

export {
  listUsers,
  listOneUser,
  deleteUser,
  deleteUserPhoto,
  saveOneAdminUser,
  updateAdminUser,
};
