import {
  listVaccines,
  listVaccinesByIds,
  getVaccine,
  saveOneVaccine,
  deleteVaccine,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q, language } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      vaccines: { items, total },
    },
  } = await listVaccines(perPage, skip, field, order, q, language);

  return { data: items, total };
};

export const getMany = async params => {
  const ids = params.ids.map(item => item);

  const {
    data: {
      vaccines: { items },
    },
  } = await listVaccinesByIds(ids);

  return { data: items };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { vaccine },
  } = await getVaccine(id);

  return { data: vaccine };
};

export const create = async params => {
  const {
    data: { name, language, type },
  } = params;

  const {
    data: { saveVaccine },
  } = await saveOneVaccine(null, name, language, type.id);

  return { data: { id: saveVaccine.id } };
};

export const update = async params => {
  const {
    data: { id, name, language, type },
  } = params;

  const {
    data: { saveVaccine },
  } = await saveOneVaccine(id, name, language, type.id);

  return { data: { id: saveVaccine.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deleteVaccine(id);

  if (deleted) return { data: { id } };
  else return null;
};
