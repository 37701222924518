import {
  listPetFurs,
  listPetFursByIds,
  getPetFur,
  saveOnePetFur,
  deletePetFur,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q, type, language } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      petFurs: { items, total },
    },
  } = await listPetFurs(perPage, skip, field, order, q, type, language);

  return { data: items, total };
};

export const getAll = async () => {
  const {
    data: {
      petFurs: { items },
    },
  } = await listPetFurs(100, 0, 'type.name', 'ASC', undefined, undefined);

  return { data: items };
};

export const getMany = async params => {
  const ids = params.ids.map(item => item.id);

  const {
    data: {
      petFurs: { items },
    },
  } = await listPetFursByIds(ids);

  return { data: items };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { petFur },
  } = await getPetFur(id);

  return { data: petFur };
};

export const create = async params => {
  const {
    data: { name, language, type },
  } = params;
  const {
    data: { savePetFur },
  } = await saveOnePetFur(null, name, language, type.id);

  return { data: { id: savePetFur.id } };
};

export const update = async params => {
  const {
    data: { id, name, language, type },
  } = params;

  const {
    data: { savePetFur },
  } = await saveOnePetFur(id, name, language, type.id);

  return { data: { id: savePetFur.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deletePetFur(id);

  return deleted ? { data: { id } } : null;
};
