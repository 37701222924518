import {
  listPetBreeds,
  listPetBreedsByIds,
  getPetBreed,
  saveOnePetBreed,
  deletePetBreed,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q, type, language } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      petBreeds: { items, total },
    },
  } = await listPetBreeds(perPage, skip, field, order, q, type, language);

  return { data: items, total };
};

export const getAll = async () => {
  const {
    data: {
      petBreeds: { items },
    },
  } = await listPetBreeds(100, 0, 'type.name', 'ASC', undefined, undefined);

  return { data: items };
};

export const getMany = async params => {
  const ids = params.ids.map(item => item.id);

  const {
    data: {
      petBreeds: { items },
    },
  } = await listPetBreedsByIds(ids);

  return { data: items };
};

export const getOne = async params => {
  const { id } = params;
  const {
    data: { petBreed },
  } = await getPetBreed(id);

  return { data: petBreed };
};

export const create = async params => {
  const {
    data: { name, language, type },
  } = params;

  const {
    data: { savePetBreed },
  } = await saveOnePetBreed(null, name, language, type.id);

  return { data: { id: savePetBreed.id } };
};

export const update = async params => {
  const {
    data: { id, name, language, type },
  } = params;

  const {
    data: { savePetBreed },
  } = await saveOnePetBreed(id, name, language, type.id);

  return { data: { id: savePetBreed.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deletePetBreed(id);

  if (deleted) return { data: { id } };
  else return null;
};
