import 'date-fns';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useInput } from 'react-admin';

import styles from './styles.module.scss';

const DatePickerInput = ({
  label,
  maxDate,
  format,
  required,
  initialFocusedDate,
  ...props
}) => {
  const {
    input: { name, onChange, value },
    meta: { touched, error },
  } = useInput(props);

  const [inputValue, setInputValue] = useState(value || null);

  const handleChangeValue = date => {
    if (onChange) onChange(date);
    setInputValue(date);
  };

  return (
    <div className={styles['date-picker-input-wrapper']}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          onChange={date => handleChangeValue(date)}
          error={!!(touched && error)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          value={inputValue}
          InputProps={{
            className: styles['input'],
          }}
          InputLabelProps={{
            className: styles['label'],
          }}
          {...{ label, name, maxDate, format, initialFocusedDate, required }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePickerInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  required: PropTypes.bool,
  initialFocusedDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

export default DatePickerInput;
