import client from 'services/graphql/client';
import { treatError } from 'helpers/graphql';
import { LIST_LOCATIONS, GET_LOCATION, LOCATION_EXISTS } from './queries';
import { SAVE_LOCATION, DELETE_LOCATION, UPLOAD } from './mutations';

const checkExistence = (name, address, id) => {
  const variables = {
    name,
    address,
  };

  if (id) variables.id = id;

  return client.query({
    query: LOCATION_EXISTS,
    variables,
  });
};

const listLocations = (
  take = 10,
  skip = 0,
  field,
  order,
  categoryName,
  subcategoryName,
  editedByName,
  q,
  regionName,
  country,
) => {
  const variables = {
    take,
    skip,
    fieldSort: field,
    order,
    country,
  };

  if (categoryName) variables.categoryName = categoryName.trim();

  if (subcategoryName) variables.subcategoryName = subcategoryName.trim();

  if (editedByName) variables.editedByName = editedByName.trim();

  if (q) variables.name = q.trim();

  if (regionName) variables.regionName = regionName.trim();

  return client.query({
    query: LIST_LOCATIONS,
    variables,
  });
};

const getLocation = id =>
  client.query({
    query: GET_LOCATION,
    variables: { id },
  });

const saveOneLocation = (
  id,
  name,
  address,
  city,
  province,
  country,
  landlinePhonesArray,
  mobilePhonesArray,
  categories,
  subcategories,
  emailsArray,
  latitude,
  longitude,
  description,
  siteUrl,
  facebookUrl,
  instagramUrl,
  internalNotes,
  hoursArray,
  responsibleVeterinary,
  banner,
) =>
  client
    .mutate({
      mutation: SAVE_LOCATION,
      variables: {
        id,
        name,
        address,
        city,
        province,
        country,
        landlinePhones: landlinePhonesArray,
        mobilePhones: mobilePhonesArray,
        categoriesIds: categories,
        subcategoriesIds: subcategories,
        emails: emailsArray,
        latitude,
        longitude,
        description,
        siteUrl,
        facebookUrl,
        instagramUrl,
        internalNotes,
        hours: hoursArray,
        responsibleVeterinary,
        banner,
      },
    })
    .catch(e => {
      throw treatError(e);
    });

const deleteLocation = id =>
  client.mutate({
    mutation: DELETE_LOCATION,
    variables: { id },
  });

const uploadBanner = file =>
  client.mutate({
    mutation: UPLOAD,
    variables: {
      context: 'locations',
      file: file.rawFile,
    },
  });

export {
  checkExistence,
  listLocations,
  getLocation,
  saveOneLocation,
  deleteLocation,
  uploadBanner,
};
