import React, { Children } from 'react';
import { linkToRecord } from 'ra-core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const CustomDatagridRow = ({ record, resource, id, children, basePath }) => {
  const history = useHistory();

  const useStyles = makeStyles({
    cursor: {
      cursor: 'pointer',
    },
    warn: {
      backgroundColor: '#FFEE94',
    },
  });

  const classes = useStyles();

  const checkWarn = () => {
    if (
      resource === 'locations' &&
      (!record.emails ||
        record.emails.length === 0 ||
        ((!record.landlinePhones || record.landlinePhones.length === 0) &&
          (!record.mobilePhones || record.mobilePhones.length === 0)))
    ) {
      return true;
    }

    return false;
  };

  const handleClick = () => {
    history.push(linkToRecord(basePath, id));
  };

  return (
    <TableRow
      key={id}
      className={[checkWarn() ? classes['warn'] : null, classes.cursor].join(
        ' ',
      )}
      record={record}
      resource={resource}
      basepath={basePath}
    >
      {Children.map(
        children,
        field =>
          field && (
            <TableCell
              key={`${id}-${field.props.source}`}
              onClick={handleClick}
            >
              {React.cloneElement(field, {
                record,
                basePath,
                resource,
              })}
            </TableCell>
          ),
      )}
    </TableRow>
  );
};

export default CustomDatagridRow;
