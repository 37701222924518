import React from 'react';
import { Screen } from 'components/templates';
import { PetTypeForm, PetTypeList } from 'components/organisms';

export const List = props => (
  <Screen header="Pet types">
    <PetTypeList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Pet type">
    <PetTypeForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Pet type">
    <PetTypeForm action="Create" {...props} />
  </Screen>
);
