import gql from 'graphql-tag';

export const SAVE_PET_BREED = gql`
  mutation savePetBreed(
    $id: ID
    $name: String!
    $language: String!
    $typeId: ID!
  ) {
    savePetBreed(id: $id, name: $name, language: $language, typeId: $typeId) {
      id
      name
      language
      type {
        id
        name
      }
    }
  }
`;

export const DELETE_PET_BREED = gql`
  mutation deletePetBreed($id: ID!) {
    deletePetBreed(id: $id)
  }
`;
