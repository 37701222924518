import client from 'services/graphql/client';
import { LIST_VACCINES, GET_VACCINE } from './queries';
import { SAVE_VACCINE, DELETE_VACCINE } from './mutations';

const listVaccines = (take = 10, skip = 0, field, order, q, language) => {
  const variables = {
    take,
    skip,
    fieldSort: field,
    order,
    language,
  };

  if (q) variables.name = q;

  return client.query({
    query: LIST_VACCINES,
    variables,
  });
};

const listVaccinesByIds = ids =>
  client.query({
    query: LIST_VACCINES,
    variables: { ids },
  });

const getVaccine = id =>
  client.query({
    query: GET_VACCINE,
    variables: { id },
  });

const saveOneVaccine = (id, name, language, typeId) =>
  client.mutate({
    mutation: SAVE_VACCINE,
    variables: {
      id,
      name,
      language,
      typeId,
    },
  });

const deleteVaccine = id =>
  client.mutate({
    mutation: DELETE_VACCINE,
    variables: { id },
  });

export {
  listVaccines,
  listVaccinesByIds,
  getVaccine,
  saveOneVaccine,
  deleteVaccine,
};
