import client from 'services/graphql/client';
import { LIST_PET_TYPES, GET_PET_TYPE } from './queries';
import { SAVE_PET_TYPE, DELETE_PET_TYPE } from './mutations';

const listPetTypes = (take = 10, skip = 0, field, order, q) => {
  const variables = {
    take,
    skip,
    fieldSort: field,
    order,
  };

  if (q) variables.name = q;

  return client.query({
    query: LIST_PET_TYPES,
    variables,
  });
};

const listPetTypesByIds = ids =>
  client.query({
    query: LIST_PET_TYPES,
    variables: { ids },
  });

const getPetType = id =>
  client.query({
    query: GET_PET_TYPE,
    variables: { id },
  });

const saveOnePetType = (id, name) =>
  client.mutate({
    mutation: SAVE_PET_TYPE,
    variables: {
      id,
      name,
    },
  });

const deletePetType = id =>
  client.mutate({
    mutation: DELETE_PET_TYPE,
    variables: { id },
  });

export {
  listPetTypes,
  listPetTypesByIds,
  getPetType,
  saveOnePetType,
  deletePetType,
};
