export const getDateFormat = type => {
  const format = {
    default: () => ({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
  };
  return (format[type] || format['default'])();
};

export const formatDateToTimeInput = date => {
  if (typeof date === 'string') return new Date(`01/01/1970 ${date}`);
  return date;
};
