import React from 'react';
import PropTypes from 'prop-types';
import { Create, Edit } from 'react-admin';

const ActionWrapper = props => {
  const { action, title, children } = props;

  switch (action) {
    case 'Create':
      return (
        <Create title={title} {...props}>
          {children}
        </Create>
      );

    case 'Edit':
      return (
        <Edit title={title} undoable={false} {...props}>
          {children}
        </Edit>
      );
    default:
      break;
  }
};

ActionWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ActionWrapper;
