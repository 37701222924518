import gql from 'graphql-tag';

export const LIST_USERS = gql`
  query allUsers(
    $ids: [ID!]
    $skip: Int
    $take: Int
    $firstName: String
    $lastName: String
    $email: String
    $fieldSort: String
    $isAdmin: Boolean
    $order: String
  ) {
    allUsers(
      ids: $ids
      skip: $skip
      take: $take
      firstName: $firstName
      lastName: $lastName
      email: $email
      fieldSort: $fieldSort
      isAdmin: $isAdmin
      order: $order
    ) {
      items {
        id
        firstName
        lastName
        email
        telephone
        birthDate
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const LIST_USER = gql`
  query userAdminPanel($id: ID!) {
    userAdminPanel(id: $id) {
      id
      firstName
      lastName
      email
      telephone
      birthDate
      active
      photo
      createdAt
      updatedAt
    }
  }
`;
