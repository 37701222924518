import React from 'react';
import { TextField } from '@material-ui/core';
import { useInput } from 'react-admin';
import InputMask from 'react-input-mask';

const TelephoneInput = props => {
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  return (
    <InputMask
      mask={props.mask}
      value={value}
      disabled={false}
      onChange={onChange}
      maskChar=" "
      {...rest}
    >
      {inputProps => (
        <TextField
          name={name}
          label={props.label}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          variant="filled"
          {...inputProps}
        />
      )}
    </InputMask>
  );
};

export default TelephoneInput;
