import React, { useState, useEffect } from 'react';
import {
  List,
  DateField,
  TextField,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { useCountry } from 'hooks/useCountry';

import { Datagrid } from 'components/atoms';
import { SearchFilter } from 'components/molecules';
import { getDateFormat } from 'helpers/date';
import { isMaster } from 'helpers/auth';

import styles from './styles.module.scss';

const LocationList = props => {
  const dataProvider = useDataProvider();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [editedByNames, setEditedByNames] = useState([]);
  const {
    countryData: { shortName },
  } = useCountry();

  const createAdminUsersArray = data =>
    data.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
    }));

  useEffect(() => {
    dataProvider
      .getAll('categories')
      .then(({ data }) => {
        setCategories(data);
      })
      .catch(error => {
        setCategories([]);
      });

    dataProvider
      .getAll('subcategories')
      .then(({ data }) => {
        setSubcategories(data);
      })
      .catch(error => {
        setSubcategories([]);
      });

    dataProvider
      .getAll('adminUsers', {
        users: { isAdmin: true },
        filter: {},
        pagination: { skip: 0, take: 50 },
      })
      .then(({ data }) => setEditedByNames(createAdminUsersArray(data)))
      .catch(error => setEditedByNames([]));
  }, [dataProvider]);

  const autocompleteInputsWithoutEditedByLabel = [
    {
      label: 'Category',
      source: 'categoryName',
      optionField: 'name',
      choices: categories,
    },
    {
      label: 'Subcategory',
      source: 'subcategoryName',
      optionField: 'name',
      choices: subcategories,
    },
  ];

  const autocompleteInputsWithEditedByLabel = [
    ...autocompleteInputsWithoutEditedByLabel,
    {
      label: 'Edited by',
      source: 'editedByName',
      optionField: 'name',
      choices: editedByNames,
    },
  ];

  return (
    <List
      bulkActionButtons={false}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filter={{ country: shortName }}
      filters={
        <SearchFilter
          searchBy="Name"
          anotherSearchInput={
            <TextInput
              label="Search by City or Province"
              source="regionName"
              className={styles['search-region']}
              alwaysOn
              resettable
            />
          }
          autocomplete={
            isMaster(props.permissions)
              ? autocompleteInputsWithEditedByLabel
              : autocompleteInputsWithoutEditedByLabel
          }
        />
      }
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Name" />
        <TextField source="address" label="Address" />
        <TextField source="city" label="City" />
        <TextField source="province" label="Province" />
        <TextField source="country" label="Country" />
        <TextField source="categories[0].name" label="Main Category" />
        {isMaster(props.permissions) && (
          <TextField source="logs[0].name" label="Edited By" />
        )}
        <DateField
          source="updatedAt"
          label="Updated at"
          options={getDateFormat()}
        />
      </Datagrid>
    </List>
  );
};

export default LocationList;
