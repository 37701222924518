import React from 'react';
import PropTypes from 'prop-types';
import { SimpleForm } from 'react-admin';
import { Toolbar } from 'components/molecules';

const FormWrapper = props => {
  const { children, ...rest } = props;

  return (
    <SimpleForm
      encType="multipart/form-data"
      toolbar={<Toolbar goBackTo={props.basepath} />}
      submitOnEnter={false}
      {...rest}
    >
      {children}
    </SimpleForm>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormWrapper;
