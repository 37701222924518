import client from 'services/graphql/client';
import { GET_PET_FUR, LIST_PET_FURS } from './queries';
import { DELETE_PET_FUR, SAVE_PET_FUR } from './mutations';

const listPetFurs = (take, skip, fieldSort, order, q, type, language) => {
  const variables = {
    take,
    skip,
    fieldSort,
    order,
    language,
  };

  if (type) variables.type = type;

  if (q) variables.name = q;

  return client.query({
    query: LIST_PET_FURS,
    variables,
  });
};

const listPetFursByIds = ids =>
  client.query({
    query: LIST_PET_FURS,
    variables: { ids },
  });

const getPetFur = id =>
  client.query({
    query: GET_PET_FUR,
    variables: { id },
  });

const saveOnePetFur = (id, name, language, typeId) =>
  client.mutate({
    mutation: SAVE_PET_FUR,
    variables: {
      id,
      name,
      language,
      typeId,
    },
  });

const deletePetFur = id =>
  client.mutate({
    mutation: DELETE_PET_FUR,
    variables: { id },
  });

export {
  listPetFurs,
  listPetFursByIds,
  getPetFur,
  saveOnePetFur,
  deletePetFur,
};
