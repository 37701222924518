import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { CancelButton } from 'components/atoms';

const ExtendedToolbar = ({ goBackTo, ...rest }) => {
  const { record } = rest;

  const history = useHistory();

  const handleCancelClick = path => {
    history.push(path);
  };

  const getDeleteText = () => {
    if (record && record.firstName) {
      return `Delete "${record.firstName}${
        record.lastName ? ' ' + record.lastName : ''
      }"`;
    } else {
      return `Delete: "${record.name}"`;
    }
  };

  return (
    <Toolbar history={history} {...rest}>
      <SaveButton />
      <CancelButton onClick={() => handleCancelClick(goBackTo)}>
        Cancel
      </CancelButton>
      {record && record.id && (
        <DeleteButton
          undoable={false}
          style={{ marginLeft: 'auto' }}
          confirmTitle={getDeleteText()}
        />
      )}
    </Toolbar>
  );
};

ExtendedToolbar.propTypes = {
  goBackTo: PropTypes.string.isRequired,
  record: PropTypes.object,
};

ExtendedToolbar.defaultProps = {
  record: undefined,
};

export default ExtendedToolbar;
