import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from 'providers/dataProvider';
import authProvider from 'providers/Auth';
import CategoryIcon from '@material-ui/icons/Category';
import SubCategoryIcon from '@material-ui/icons/Subject';
import PartnerIcon from '@material-ui/icons/Store';
import PetBreedIcon from '@material-ui/icons/Fingerprint';
import PetTypeIcon from '@material-ui/icons/SortByAlpha';
import PetFurIcon from '@material-ui/icons/Waves';
import VaccinesIcon from '@material-ui/icons/Colorize';
import AdminUsersIcon from '@material-ui/icons/PersonOutline';
import AppUsersIcon from '@material-ui/icons/Person';
import { CountryContextProvider } from 'contexts/CountryContext';

import { Layout } from 'components/molecules';
import {
  Dashboard,
  Categories,
  Subcategories,
  Locations,
  PetTypes,
  PetBreeds,
  PetFurs,
  Vaccines,
  AdminUsers,
  AppUsers,
} from 'components/pages';

import { isMaster } from 'helpers/auth';

const App = ({ history }) => (
  <CountryContextProvider>
    <Admin
      title="Helpet Dashboard"
      layout={Layout}
      dashboard={Dashboard}
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {permissions => [
        <Resource
          name="locations"
          list={Locations.List}
          edit={Locations.Edit}
          create={Locations.Create}
          icon={PartnerIcon}
          options={{ label: 'Partners' }}
        />,
        <Resource
          name="categories"
          list={Categories.List}
          edit={Categories.Edit}
          create={Categories.Create}
          icon={CategoryIcon}
        />,
        <Resource
          name="subcategories"
          list={Subcategories.List}
          edit={Subcategories.Edit}
          create={Subcategories.Create}
          icon={SubCategoryIcon}
        />,
        <Resource
          name="petBreeds"
          list={PetBreeds.List}
          edit={isMaster(permissions) && PetBreeds.Edit}
          create={PetBreeds.Create}
          icon={PetBreedIcon}
          options={{ label: 'Pet breeds' }}
        />,
        <Resource
          name="petTypes"
          list={PetTypes.List}
          edit={isMaster(permissions) && PetTypes.Edit}
          create={PetTypes.Create}
          icon={PetTypeIcon}
          options={{ label: 'Pet types' }}
        />,
        <Resource
          name="petFurs"
          list={PetFurs.List}
          edit={isMaster(permissions) && PetFurs.Edit}
          create={PetFurs.Create}
          icon={PetFurIcon}
          options={{ label: 'Pet furs' }}
        />,
        <Resource
          name="vaccines"
          list={Vaccines.List}
          edit={isMaster(permissions) && Vaccines.Edit}
          create={Vaccines.Create}
          icon={VaccinesIcon}
          options={{ label: 'Vaccines' }}
        />,
        isMaster(permissions) && (
          <Resource
            name="adminUsers"
            list={AdminUsers.List}
            create={AdminUsers.Create}
            edit={AdminUsers.Edit}
            icon={AdminUsersIcon}
            options={{ label: 'Admin Users' }}
          />
        ),
        isMaster(permissions) && (
          <Resource
            name="appUsers"
            list={AppUsers.List}
            create={AppUsers.Create}
            edit={AppUsers.Edit}
            icon={AppUsersIcon}
            options={{ label: 'App Users' }}
          />
        ),
      ]}
    </Admin>
  </CountryContextProvider>
);

export default App;
