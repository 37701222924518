import gql from 'graphql-tag';

export const SAVE_PET_TYPE = gql`
  mutation savePetType($id: ID, $name: String!) {
    savePetType(id: $id, name: $name) {
      id
    }
  }
`;

export const DELETE_PET_TYPE = gql`
  mutation deletePetType($id: ID!) {
    deletePetType(id: $id)
  }
`;
