// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_input-wrapper__3KAXl {\n  position: relative;\n  max-width: 400px; }\n  .styles_input-wrapper__3KAXl > div:first-of-type {\n    min-width: 200px; }\n", ""]);
// Exports
exports.locals = {
	"input-wrapper": "styles_input-wrapper__3KAXl"
};
module.exports = exports;
