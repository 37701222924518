import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectInput, required } from 'react-admin';
import { ActionWrapper, FormWrapper } from 'components/atoms';
import { useCountry } from 'hooks/useCountry';

const CategoryForm = props => {
  const { action, history, ...rest } = props;
  const { countryData } = useCountry();

  return (
    <ActionWrapper action={action} title={`${action} Category`} {...rest}>
      <FormWrapper history={history} redirect="list" basepath="/categories">
        <SelectInput
          source="language"
          choices={
            action === 'Create'
              ? countryData.country === 'italy'
                ? [{ id: 'it', name: 'Italy' }]
                : [{ id: 'pt', name: 'Brazil' }]
              : [
                  { id: 'pt', name: 'Brazil' },
                  { id: 'it', name: 'Italy' },
                ]
          }
          label="Country"
          options={{ disabled: true }}
          initialValue={countryData.country === 'italy' ? 'it' : 'pt'}
        />
        <TextInput
          source="name"
          label="Category name"
          validate={required('Category name is required')}
        />
      </FormWrapper>
    </ActionWrapper>
  );
};

CategoryForm.propTypes = {
  action: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default CategoryForm;
