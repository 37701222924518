import React from 'react';
import { Screen } from 'components/templates';
import { VaccineList, VaccineForm } from 'components/organisms';

export const List = props => (
  <Screen header="Vaccines">
    <VaccineList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Vaccine">
    <VaccineForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Vaccine">
    <VaccineForm action="Create" {...props} />
  </Screen>
);
