import {
  listUsers,
  listOneUser,
  saveOneUser,
  updateAppUser,
  deleteUser,
  deleteUserPhoto,
} from './actions';

export const getList = async params => {
  const { perPage, page } = params.pagination;
  const { field, order } = params.sort;
  const { q, lastName, email } = params.filter;
  const skip = (page - 1) * perPage;

  const {
    data: {
      allUsers: { items, total },
    },
  } = await listUsers({
    users: { firstName: q, lastName, email, isAdmin: false },
    filter: { fieldSort: field, order },
    pagination: { skip, take: perPage },
  });

  return { data: items, total };
};

export const getAll = async params => {
  const {
    data: {
      users: { items },
    },
  } = await listUsers({ ...params });

  return { data: items };
};

export const getOne = async params => {
  const {
    data: { userAdminPanel },
  } = await listOneUser(params);

  return { data: userAdminPanel };
};

export const create = async params => {
  const {
    data: { saveUser },
  } = await saveOneUser(params.data);

  return { data: { id: saveUser.id } };
};

export const update = async params => {
  const {
    data: { updateAppUser: updatedUser },
  } = await updateAppUser(params.data);

  return { data: { id: updatedUser.id } };
};

export const deleteOne = async params => {
  const { id } = params;

  const deleted = await deleteUser(id);

  if (deleted) return { data: { id } };
  else return null;
};

export const deletePhoto = async id => {
  const { data } = await deleteUserPhoto(id);

  return data.deleteUserPhoto;
};
