// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_input-wrapper__2eGI1 {\n  position: relative; }\n  .styles_input-wrapper__2eGI1 > div:first-of-type {\n    margin: 0 0 20px -8px;\n    justify-content: flex-start; }\n\n.styles_transfer-list-card-header__1VUyt {\n  padding: 8px 10px !important; }\n  .styles_transfer-list-card-header__1VUyt > div:first-of-type {\n    margin-right: 10px; }\n\n.styles_transfer-list-button__1NSZJ {\n  margin: 4px 0px !important; }\n\n.styles_transfer-list-container-list__1NF9I {\n  width: 200px;\n  height: 230px;\n  overflow: auto; }\n", ""]);
// Exports
exports.locals = {
	"input-wrapper": "styles_input-wrapper__2eGI1",
	"transfer-list-card-header": "styles_transfer-list-card-header__1VUyt",
	"transfer-list-button": "styles_transfer-list-button__1NSZJ",
	"transfer-list-container-list": "styles_transfer-list-container-list__1NF9I"
};
module.exports = exports;
