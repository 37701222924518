import gql from 'graphql-tag';

export const LIST_PET_TYPES = gql`
  query petTypes(
    $ids: [ID!]
    $name: String
    $take: Int
    $skip: Int
    $fieldSort: String
    $order: String
  ) {
    petTypes(
      ids: $ids
      name: $name
      take: $take
      skip: $skip
      fieldSort: $fieldSort
      order: $order
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const GET_PET_TYPE = gql`
  query petType($id: ID!) {
    petType(id: $id) {
      id
      name
    }
  }
`;
