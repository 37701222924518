import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import {
  Filter,
  TextInput,
  AutocompleteInput,
  changeListParams,
} from 'react-admin';

const SearchFilter = ({
  searchBy,
  autocomplete,
  anotherSearchInput,
  resource,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const searchLabel = `Search${searchBy ? ` by ${searchBy}` : ''}`;

  const debouncedSetFilters = debounce((filter = {}) => {
    const query = { filter };

    dispatch(changeListParams(resource, query));
  }, 500);

  const setFilters = useCallback(debouncedSetFilters, [
    resource,
    dispatch,
    changeListParams,
  ]);

  return (
    <Filter resource={resource} setFilters={setFilters} {...restProps}>
      <TextInput label={searchLabel} source="q" alwaysOn resettable />
      {anotherSearchInput}
      {autocomplete &&
        autocomplete.map(({ label, source, optionField, choices }) => (
          <AutocompleteInput
            key={optionField}
            label={label}
            source={source}
            choices={choices}
            optionText={optionField}
            optionValue={optionField}
            shouldRenderSuggestions={val => {
              return val && val.trim().length > 1;
            }}
          />
        ))}
    </Filter>
  );
};

SearchFilter.propTypes = {
  resource: PropTypes.string,
  searchBy: PropTypes.string,
  anotherSearchInput: PropTypes.element,
  autocomplete: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      optionField: PropTypes.string.isRequired,
      choices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }),
  ),
};

export default SearchFilter;
