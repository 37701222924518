import React from 'react';
import { List, DateField, TextField, TextInput } from 'react-admin';
import { getDateFormat } from 'helpers/date';
import { Datagrid } from 'components/atoms';
import { SearchFilter } from 'components/molecules';

const AdminUserList = props => (
  <List
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    filters={
      <SearchFilter
        searchBy="first name"
        anotherSearchInput={[
          <TextInput
            label="Search by last name"
            source="lastName"
            alwaysOn
            resettable
          />,
          <TextInput
            label="Search by email"
            source="email"
            alwaysOn
            resettable
          />,
        ]}
      />
    }
    {...props}
  >
    <Datagrid>
      <TextField source="email" label="Email" />
      <TextField source="firstName" label="First name" />
      <TextField source="lastName" label="Lastname" />
      <TextField source="telephone" label="Phone number" />
      <DateField
        source="updatedAt"
        label="Updated at"
        options={getDateFormat()}
      />
      <DateField
        source="createdAt"
        label="Created at"
        options={getDateFormat()}
      />
    </Datagrid>
  </List>
);

export default AdminUserList;
