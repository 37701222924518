import 'date-fns';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { useInput } from 'react-admin';

const TimeInput = props => {
  const {
    input: { name, onChange, value },
    meta: { touched, error },
  } = useInput(props);
  const [inputValue, setInputValue] = useState(value || null);

  const { label = undefined } = props;

  const handleChangeValue = date => {
    if (onChange) onChange(date);
    setInputValue(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        onChange={date => handleChangeValue(date)}
        error={!!(touched && error)}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        value={inputValue}
        {...{ label, name }}
      />
    </MuiPickersUtilsProvider>
  );
};

TimeInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};

export default TimeInput;
