import gql from 'graphql-tag';

export const SAVE_LOCATION = gql`
  mutation saveLocation(
    $id: ID
    $name: String!
    $address: String!
    $city: String!
    $province: String!
    $country: String!
    $landlinePhones: [String!]
    $mobilePhones: [LocationMobilePhonesInput!]
    $categoriesIds: [ID!]!
    $subcategoriesIds: [ID!]!
    $emails: [String!]
    $latitude: String!
    $longitude: String!
    $description: String
    $siteUrl: String
    $instagramUrl: String
    $facebookUrl: String
    $internalNotes: String
    $hours: [LocationHoursInput!]
    $responsibleVeterinary: String
    $banner: String
  ) {
    saveLocation(
      id: $id
      locationInput: {
        name: $name
        address: $address
        city: $city
        province: $province
        country: $country
        landlinePhones: $landlinePhones
        mobilePhones: $mobilePhones
        categoriesIds: $categoriesIds
        subcategoriesIds: $subcategoriesIds
        emails: $emails
        latitude: $latitude
        longitude: $longitude
        description: $description
        siteUrl: $siteUrl
        instagramUrl: $instagramUrl
        facebookUrl: $facebookUrl
        internalNotes: $internalNotes
        hours: $hours
        responsibleVeterinary: $responsibleVeterinary
        banner: $banner
      }
    ) {
      id
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id)
  }
`;

export const UPLOAD = gql`
  mutation upload($context: String!, $file: Upload!) {
    upload(context: $context, file: $file)
  }
`;
