import client from 'services/graphql/client';
import { LIST_PET_BREEDS, GET_PET_BREED } from './queries';
import { SAVE_PET_BREED, DELETE_PET_BREED } from './mutations';

const listPetBreeds = (take, skip, field, order, q, type, language) => {
  const variables = {
    take,
    skip,
    fieldSort: field,
    order,
    language,
  };

  if (type) variables.type = type;

  if (q) variables.name = q;

  return client.query({
    query: LIST_PET_BREEDS,
    variables,
  });
};

const listPetBreedsByIds = ids =>
  client.query({
    query: LIST_PET_BREEDS,
    variables: { ids },
  });

const getPetBreed = id =>
  client.query({
    query: GET_PET_BREED,
    variables: { id },
  });

const saveOnePetBreed = (id, name, language, typeId) =>
  client.mutate({
    mutation: SAVE_PET_BREED,
    variables: {
      id,
      name: name.trim(),
      language,
      typeId,
    },
  });

const deletePetBreed = id =>
  client.mutate({
    mutation: DELETE_PET_BREED,
    variables: { id },
  });

export {
  listPetBreeds,
  listPetBreedsByIds,
  getPetBreed,
  saveOnePetBreed,
  deletePetBreed,
};
