import React from 'react';

import { AppUserList, AppUserForm } from 'components/organisms';
import { Screen } from 'components/templates';

export const List = props => (
  <Screen header="List Users">
    <AppUserList {...props} />
  </Screen>
);

export const Edit = props => (
  <Screen header="Edit User">
    <AppUserForm action="Edit" {...props} />
  </Screen>
);

export const Create = props => (
  <Screen header="Create User">
    <AppUserForm action="Create" {...props} />
  </Screen>
);
