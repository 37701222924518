import gql from 'graphql-tag';

export const DELETE_USER = gql`
  mutation deleteUserAdminPanel($id: ID!) {
    deleteUserAdminPanel(id: $id)
  }
`;

export const DELETE_USER_PHOTO = gql`
  mutation deleteUserPhoto($id: ID!) {
    deleteUserPhoto(id: $id)
  }
`;

export const SAVE_APP_USER = gql`
  mutation saveUser(
    $firstName: String!
    $lastName: String!
    $email: Email!
    $telephone: String!
    $birthDate: Date
  ) {
    saveUser(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        telephone: $telephone
        birthDate: $birthDate
      }
    ) {
      id
      firstName
      lastName
      email
      telephone
      birthDate
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_APP_USER = gql`
  mutation updateAppUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: Email
    $telephone: String
    $birthDate: Date
    $active: Boolean
    $password: String
  ) {
    updateAppUser(
      id: $id
      adminUserInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        telephone: $telephone
        birthDate: $birthDate
        active: $active
        password: $password
      }
    ) {
      id
      firstName
      lastName
      email
      telephone
      birthDate
      active
      createdAt
      updatedAt
    }
  }
`;
