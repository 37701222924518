import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { ImageInput as Input, ImageField } from 'react-admin';
import { useField } from 'react-final-form';

import 'react-image-crop/dist/ReactCrop.css';

import { getCroppedImage } from 'helpers/file';

const ImageInput = ({ source, filename }) => {
  const {
    input: { onChange },
  } = useField(source);
  const [cropSrc, setCropSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 34 / 10 });
  const imageRef = useRef(null);

  const handleImageLoaded = image => (imageRef.current = image);

  const makeClientCrop = async crop => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImage(
        imageRef.current,
        crop,
        filename,
      );
      const reader = new FileReader();
      reader.readAsDataURL(croppedImageUrl);
      reader.onloadend = () =>
        onChange({
          src: reader.result,
          rawFile: croppedImageUrl,
        });
    }
  };

  const handleImageDrop = image => {
    const reader = new FileReader();
    reader.readAsDataURL(new Blob(image));
    reader.onloadend = () => setCropSrc(reader.result);
  };

  return (
    <>
      <Input
        source={source}
        maxSize={1000000}
        label="Photo (maximum size: 1MB)"
        accept="image/*"
        options={{
          onDrop: handleImageDrop,
        }}
      >
        <ImageField source="src" />
      </Input>
      <ReactCrop
        crop={crop}
        src={cropSrc}
        onChange={setCrop}
        onComplete={makeClientCrop}
        onImageLoaded={handleImageLoaded}
      />
    </>
  );
};

ImageInput.propTypes = {
  source: PropTypes.string,
  filename: PropTypes.string,
};

ImageInput.defaultProps = {
  source: '',
  filename: '',
};

export default ImageInput;
