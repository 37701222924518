import React from 'react';
import { Screen } from 'components/templates';

const Dashboard = () => {
  return (
    <Screen header="Dashboard">
      <p>Welcome to Helpet Administration board!</p>
    </Screen>
  );
};

export default Dashboard;
